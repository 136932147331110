import React, { useState, memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';



import { filter, getAllSectionWithReports as QUERY } from '../../services/api/queryService';
import { logout as LOGOUT } from '../../services/api/mutationService';

import { sections } from '../../lib/constants/fields';

/** Redux */
import { useSelector } from 'react-redux';
import ModalForm from '../modal/ModalForm';
import PasswordFormFields from '../../view/userManagement/users/formFields/PasswordFormFields';
import EmailFormFields from '../../view/userManagement/users/formFields/EmailFormFields';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SideBar = ({ userPermissions, permissions, user, resetTitleHandler }) => {
  const [activeElement, setActiveElement] = useState('');
  const [activeMenu, setActiveMenu] = useState('');
  const [subsections, setSubsections] = useState({});
  const [sectionsData, setSectionsData] = useState({});
  const location = useLocation();
  const history = useHistory();
  const [ activeManufacturers, setActiveManufacturers] = useState([]);

  const selectedSection = new URLSearchParams(location.search).get('section');
  const selectedSubsection = new URLSearchParams(location.search).get('subsection');

  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT);

  const getActiveSubmenu = () => {
    let val = location.pathname.replace('/', '').replace('-', ' ')
    
    if(location.pathname == '/analytics'){
      val = location.search.split('subsection=')[1].split('&')[0].replaceAll('-', ' ')

    }else if(location.pathname.includes('/products') || location.pathname.includes('/listings') || location.pathname.includes('/unit-economics') || location.pathname.includes('/product-logs')){
      val = 'products'
    }else if(location.pathname.includes('/manufacturers') ){
      val = 'manufacturers'
    }

    return val
  }

  const tabStandartize = (str) => {
    if(str){
      str = str.replaceAll(/\s+/g, '-');
      str = str.toLowerCase()
    }
    return str;
  }

  useEffect(() => {
    resetTitleHandler()
    
    let am = ''
    if(location.pathname == '/analytics'){
      am = decodeURI(location.search.split('section=')[1].split('&')[0].replaceAll('-', ' ').toLowerCase())
    }else if(location.pathname.includes('/manufacturers') || location.pathname.includes('/products') || 
      location.pathname.includes('/listings') || location.pathname.includes('/unit-economics') || location.pathname.includes('/product-logs')){
      am = 'PIM'
    }else if(location.pathname.includes('upload')){
      am = 'Uploads'
    }else{
      am = 'Settings'
    }


    setActiveElement(am)
    setActiveMenu(am)

  }, [location])

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      section: '',
      subsection: '',
      filter: user.id
    }
  });

  useEffect(() => {
    if (data) {
      const newData = {
        getSections: {
          data: data.getAllSectionWithReports,
        }
      }
      if(newData){
        const tempSubsection = [];

        const tempSectionData = [...newData.getSections.data.sort((a, b) => {
          return a.position - b.position
        })]
        setSectionsData(tempSectionData)

        tempSectionData.map(el => {
          const pr = permissions.find(x => x.id == `9${el.roleId}` && x.read)

          if(pr && pr.read){
            tempSubsection.push({
              value: el.subsection,
              label: el.subsection,
            })
          }
        });



        const unique = [...new Set(tempSubsection.map(x => x.value))]
        const subs = unique.map((x) => {
          return { label: x, value: x }
        })


        setSubsections(subs)
      }
    }
    
  }, [data])

  window.addEventListener('activeManufacturerChange', (e) => {
    setActiveManufacturers(JSON.parse(localStorage.getItem('active_manufacturers')));
  }); 
  
  const sideBarDisplay = useSelector(state => state.sideBar.display);

  // userPermissions[11].read = true
  // userPermissions[12].read = true


  const sectionsFiltered = sections
    .map(section => ({
      ...section,
      subsections: section.subsections.filter(subsection => userPermissions[subsection.id].read && subsection.path)
    }));

    const menuCategories = [
      "Analytics",
      "Retail", 
      "Media",
      "Pricing",
      "Operations",
      "Controlling",
      "Inventory",
      "Digital Shelf",
      "Billing",
      "Accounting",
      "PnL",
    ]


    sectionsFiltered.map(x => {
      const analytics = menuCategories.includes(x.name)

      if(analytics){
        x.subsections = []
  
        if(subsections.length){
          const subsArray = []

          sectionsData.filter(w => w.section == x.name).map((el, i) => {
            if(el){
              const pr = permissions.find(x => x.id == `9${el.roleId}` && x.read)
              if(pr && pr.read){
                subsArray.push(el.subsection)
              }
            }
          })

          const unique = [...new Set(subsArray.map(w => w))]

          
          
          unique.map((w, i) => {
            const arr = sectionsData.filter(a => a.section == x.name && a.subsection == w && a.report)
            let report = null

            if(arr.length > 0){
              report = [...arr.sort((a, b) => {
                return a.user_tab_position - b.user_tab_position
              })][0]
            }

            x.subsections.push({
              id: 100 + i,
              access: 'readonly',
              name: w,
              path: `/analytics?section=${x.name.toLowerCase()}&subsection=` + w.replaceAll(' ', '-').toLowerCase() + `&tab=${tabStandartize(report?.tab)}&id=${report?.report?.id}`
            })            
          })
        
          x.name = x.name.toLowerCase()

        }
      }
  }) 



  const makeActive = str => {
    if (activeMenu !== str) {
      setActiveMenu(str);
    } else {
      setActiveMenu('');
    }
  };

  const activeSubmenu = getActiveSubmenu()

  const [itemId, setItemId] = useState(null);
  const [openModalId, setOpenModalId] = useState();

  
  const actionClickHandler = (itemId, modalId) => {
    setItemId(itemId);
    setOpenModalId(modalId);
  };

  const logoutHandler = e => {
    e.preventDefault();

    const authToken = localStorage.getItem('auth_token');

    if (authToken) {
      logout({ variable: { apiToken: authToken } });

      if (!logoutLoading) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('loggedIn')
        window.location.href = '/login';
      }
    }
  };

  const sectionTypes = [
    'Brand Management',
    'Internal Analytics'
  ]



  useEffect(() => { 
    const f = sectionsFiltered.find(x => x.subsections.length > 0)

    console.log(f, location.pathname)
    if(selectedSection && selectedSubsection && f){
      const ff = sectionsFiltered.find(x => tabStandartize(x.name) == selectedSection && x.subsections.find(w => tabStandartize(w.name.toLowerCase()) == selectedSubsection))

      if(!ff){
        const h = sectionsFiltered.find(x => x.subsections.length > 0)
        history.push(h.subsections[0].path)
      }

    }else if(f && (location.pathname == '/redirect' || location.pathname == '/') && f.name != "PIM"){
      history.push(f.subsections[0].path)
    }
  }, [sectionsFiltered, selectedSection, selectedSection])
  

  return (
    <>
    { Object.keys(subsections).length == 0 && ( 
      <div className='init_loader'>
        <div className="spinner">Spinner</div>
        <p>It might take a few moments to load the page</p>
      </div>
    )}
    <div className="left-side-menu" style={{ display: sideBarDisplay ? 'flex' : 'none' }}>
      
      {openModalId === 'email' && (
              <ModalForm setModalOpen={setOpenModalId}>
                <EmailFormFields
                  userId={itemId}
                  setOpenModalId={setOpenModalId}
                  refetch={refetch}
                />
              </ModalForm>
            )}

            {openModalId === 'password' && (
              <ModalForm setModalOpen={setOpenModalId}>
                <PasswordFormFields
                  userId={itemId}
                  setOpenModalId={setOpenModalId}
                  refetch={refetch}
                />
              </ModalForm>
            )}
      <div className="slimscroll-menu "> {/* Only show Internal Analytics when no active manufacturer is selected in the filter */}
        {sectionTypes.map(type => ( 
          ( (activeManufacturers == undefined || activeManufacturers.length == 0) || (activeManufacturers.length > 0 && type != 'Internal Analytics') ) && (
          <div id="sidebar-menu">
            { sectionsFiltered.filter(x => x.subsections.length > 0 && x.name != 'Settings' && x.type == type).length !== 0 && (
            <>
              <p className='menuTitle'>{type}</p>

              <ul className="metismenu" id="side-menu">
                {sectionsFiltered.filter(x => x.subsections.length > 0 && x.name != 'Settings' && x.type == type).map(section => (
                  <li
                    key={section.name}
                    data-to={section.name}
                    data-menu={activeElement === section.name ? 'active' : ''}
                    className={activeMenu === section.name ? 'active' : ''}
                  >
                    <div
                      to={`${location.pathname}${location.search}`}
                      onClick={() => makeActive(section.name)}
                      aria-expanded={activeMenu === section.name ? 'true' : 'false'}
                    >
                      <i className={section.icon} />
                      <span>{section.name}</span>
                      <span className="menu-arrow" />
                    </div>
                    <ul
                      className={`nav-second-level DIN2014 collapse ${activeMenu === section.name ? 'in' : ''}`}
                      aria-expanded={activeMenu === section.name ? 'false' : 'true'}
                    >
                      {section.subsections.map(subsection => (
                        <li key={subsection.id}>
                          <Link to={`${subsection.path.replaceAll(/\s+/g, '-')}`} data-to={subsection.name} aria-expanded="true" className={activeElement === section.name && subsection.name.toLowerCase() == activeSubmenu.toLowerCase() ? 'bold-item' : ''}>
                            <span>{subsection.name}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
            ) }

          </div>
          )
        ))}


      </div>

      <div className="slimscroll-menu " style={{marginBottom: '40px'}}>
      
        <div className="divider"></div>  

        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            {sectionsFiltered.filter(x => x.subsections.length > 0 && x.name == 'Settings').map(section => (
              <li
                key={section.name}
                data-to={section.name}
                data-menu={activeElement === section.name ? 'active' : ''}
                className={activeMenu === section.name ? 'active' : ''}
              >
                <div
                  to={`${location.pathname}${location.search}`}
                  onClick={() => makeActive(section.name)}
                  aria-expanded={activeMenu === section.name ? 'true' : 'false'}
                >
                  <i className={section.icon} />
                  <span>{section.name}</span>
                  <span className="menu-arrow" />
                </div>
                <ul
                  className={`nav-second-level DIN2014 collapse ${activeMenu === section.name ? 'in' : ''}`}
                  aria-expanded={activeMenu === section.name ? 'false' : 'true'}
                >
                  {section.subsections.map(subsection => (
                    <li key={subsection.id}>
                      <Link to={subsection.path} data-to={subsection.name} aria-expanded="true" className={subsection.name.toLowerCase() == activeSubmenu.toLowerCase() ? 'bold-item' : ''}>
                        <span>{subsection.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ))}

            <li
                key={user.id}
                data-to={user.id}
                className='abs_bottom'
              >
                <div
                  onClick={() => makeActive(`user${user.id}`)}
                  aria-expanded={activeMenu === `user${user.id}` ? 'true' : 'false'}
                >
                  <i className={'fas fa-user'} />
                  <span>{user.first_name} {user.last_name[0]}.</span>
                  <span className="menu-arrow" />
                </div>
                <ul
                  className={`nav-second-level DIN2014 collapse ${activeMenu === `user${user.id}` ? 'in' : ''}`}
                >
                  <li key={1} >
                    <a className="cursor" onClick={actionClickHandler.bind(null, user.id, 'email')}>
                      <span>Change email</span>
                    </a>
                  </li>
                  <li key={2} >
                    <a className="cursor"  onClick={actionClickHandler.bind(null, user.id, 'password')}>
                      <span>Change password</span>
                    </a>
                  </li>
                  <li key={2} >
                    <a className="cursor"  onClick={logoutHandler}>
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default memo(SideBar);

import React, { useEffect, useMemo, useRef, useState } from 'react';

/** Apollo */
import { useQuery, useMutation } from '@apollo/react-hooks';

/** Components */
import { getReportByReportName, unitEconomics } from '../../services/api/queryService';

import Fallback from '../../components/error-boundary/Fallback';

import { Report } from 'react-powerbi-client';

import UnitEconomics from './unitEconomics.scss';

const { localStorage } = window;

const UnitEconomicsTable = ({ data }) => {
    const [ unitEconomicsData, setUnitEconomicsData ] = useState([]);
    const [accessToken, setAccessToken] = useState('')
    const [dataPower, setDataPower] = useState(null);
    const ref = useRef(null)
    const [ratio, setRatio] = useState(100)
    const [loader, setLoader] = useState(true)
    const [showReport, setShowReport] = useState(true)
    const [errorObj, setErrorObj] = useState(null)

    const {data: dataUnitEconomics, loading: loadingUnitEconomics } = useQuery(unitEconomics, {
        variables: {
            pid: data.product_parent_id
        }
    });

    useEffect(() => {
        if(!loadingUnitEconomics && dataUnitEconomics && dataUnitEconomics.unit_economics){
            setUnitEconomicsData([...dataUnitEconomics.unit_economics])
        }
    }, [loadingUnitEconomics, dataUnitEconomics])

    const {data: dataReport, loading } = useQuery(getReportByReportName, {
        variables: {
            name: `ELMO_PDP_Unit_Economics`
        }
    });

    const appUri = 'https://azure-api.ebazaaris.io/api/power-bi'

    useEffect(() => {
        const powerbiToken = localStorage.getItem('powerbi_token')
        if(powerbiToken){
          setAccessToken(JSON.parse(powerbiToken).token)
        }
    }, [])

    useMemo(() => {
        if (dataReport) {
            if (dataReport && dataReport.getReportByReportName) {
                setDataPower(dataReport.getReportByReportName)
            }
        }
    }, [dataReport])


    useEffect(() => {

        const obj = {}

        if(data.productMarketplaces.length == 0){
            obj.title = 'No marketpalces'
        }

        if(data.status == 0){
            obj.title = 'The PID status is archived'
            obj.subtitle = 'Missing information:'
            obj.errorList = []
            obj.errorList.push('PID status = active')
            obj.errorList.push('PID status = in setup')
        }else if(data.productMarketplaces.length > 0){
            let d = data.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU' && x.seller_id != 1)

            if(d.length !== 0){
                obj.title = 'The PID is not sold via Seller eBazaaris'
                obj.subtitle = 'Missing information:'
                obj.errorList = []
                obj.errorList.push('Seller = eBazaaris')
            }else{
                let d = data.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU' && x.status == 0)


                if(d.length == data.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU').length){
                    obj.title = 'All Marketpalces (SKU\'s) with Seller = eBazaaris are achived but the PID is active'
                    obj.subtitle = 'One of the bellow SKUs must be active:'
                    obj.errorList = []
                    data.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU').map(x => {
                        obj.errorList.push(x.marketplace_product_id)
                    })
                }else if(unitEconomicsData.length > 0){
                    let d = unitEconomicsData.filter(x => x.price_type == "Min. 0.00%" && x.error != null)

                    if(d.length > 0){
                        obj.title = 'The PID doesn\'t have all required information for unit economics to perform the calculation'
                        obj.subtitle = 'Missing information:'
                        obj.errorList = []

                        d.map(x => {
                            [...x.error.split(',')].map(w => obj.errorList.push(w.trim()))
                        })

                        obj.errorList = [...new Set(obj.errorList)]
                    }
                }

            }
        }




        if(Object.keys(obj).length == 0){
            setShowReport(true)
        }else{
            setShowReport(false)
            obj.link = `/products/${data.slug}/edit`
        }

        setErrorObj({...obj})

    }, [unitEconomicsData])


    useEffect(() => {
        setRatio( Math.floor(ref.current?.clientWidth / +(1350/630)))
    }, [loader])


    useEffect(() => {
        
        function handleResize() {
            setRatio(Math.floor(ref.current?.clientWidth / +(1350/630)))
        }

        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, [])

    const onEmbedded = (embed) => {

        embed.on("loaded", function(){
            setLoader(false)
        })

    }


    if(dataPower){
        let embededUrl = '';

        const extraSettings = {
            filterPaneEnabled: false, //true
            navContentPaneEnabled: false, //true
        };


        if(accessToken){

            embededUrl = `${dataPower.embedUrl}&filter=PIM/a_parent_id_s%20eq%20%27${data.slug}%27`
        }

        return (
            <div>
                { showReport ? (
                    <>
                        {dataPower && accessToken && !loading ? (
                            <>
                                <div style={{ height: `${ratio}px`}} ref={ref} className={`app-embed ${loader == false ? '' : 'hidden'}`}>
                                    <Report
                                        style={{ height: `100%`}}
                                        className="powerBiEmbed"
                                        id={dataPower.reportId}
                                        embedUrl={embededUrl}
                                        accessToken={accessToken}
                                        filterPaneEnabled={false}
                                        navContentPaneEnabled={false}
                                        onEmbedded={onEmbedded}
                                        extraSettings={extraSettings}
                                    />
                                </div>
                                <div className={`spinner absolute ${loader === true ? '' : 'hidden'}`}>Spinner</div>
                            </>
                        ) : (
                            <div className="spinner absolute">Spinner</div>
                        )}
                    </>
                ) : (
                    <div className='unit_ec_error'>
                        <Fallback customError={errorObj} />
                    </div>
                )}

            </div>
        )
    }else{
        return (
            <div className="d-flex align-items-center justify-content-center" style={style.div}>
                <img src="/assets/images/logoOP50.png" className="reposnive-img" style={style.img} alt=""></img>
            </div>
        )
    }

}

const style = {
    div: {
        height: '350px'
    },
    img : {
        height: '100%'
    }
}


export default UnitEconomicsTable;
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

import ModalError from '../../../components/modal/ModalError';
import { createBundleItem, updateBundleItem, deleteBundleItem } from '../../../services/api/mutationService';
import { filter } from '../../../services/api/queryService';

const BundledItemsFormModal = ({setModalOpen, itemId, product_parent_id, itemsCount}) => {
  const [formData, setFormData] = useState({
    product_qty: itemId ? itemId.product_qty : [],
    bundled_product_parent_id: []
  });
  const [error, setError] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [pids, setPids] = useState([]);
  const { data, loading: dataLoading } = useQuery(filter, {
    variables: {
      type: 'product',
    },
    fetchPolicy: 'no-cache'
  });


 useEffect(() => {
  if(data && data.filter.length){
    const arr = JSON.parse(data.filter.find(x => x.name == 'pid').data)
    const arr1 = JSON.parse(data.filter.find(x => x.name == 'gtin').data)
    setPids([...arr, ...arr1])
  }
 }, [data])

  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);


  useEffect(() => {
    if (itemId) {
      setFormData({
        product_qty: itemId.product_qty,
        bundled_product_parent_id: itemId.product.slug
      })
    }
  }, [itemId])

  const dispatch = useDispatch();

  const changeFieldHandler = (event, index) => {
    setChangesMade(true);

    const arr = [...formData.product_qty]

    arr[index] = event.target.value

    setFormData({
      ...formData,
      [event.target.id]: [...arr]
    });
  };

  const [createBundleItemMutation, { loading, error: updateError }] = useMutation(
    createBundleItem
  );

  const [updateBundleItemMutation, { }] = useMutation(
    updateBundleItem
  );

  const [deleteBundleItemMutation, { }] = useMutation(
    deleteBundleItem
  );

  const deleteItem = async () => {
    try {
      const { data } = await deleteBundleItemMutation({
        variables: {
          id: itemId.id,
          product_parent_id: product_parent_id
        }
      })

      setModalOpen(false);
    } catch (err) {
      setError(err);
    }
  }

  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();
    let dataInputs = {
      bundled_product_parent_id : formData.bundled_product_parent_id, 
      product_parent_id: product_parent_id,
      product_qty: formData.product_qty
    }
    if (itemId) {
      dataInputs = [
        {
          id : itemId.id,
          bundled_product_parent_id : formData.bundled_product_parent_id, 
          product_parent_id: product_parent_id,
          product_qty: formData.product_qty
        }
      ]
    }else{
      if(formData.bundled_product_parent_id.length == 1 && formData.product_qty[0] == 1 && itemsCount == 0){
        setError('Single item should be more than 1 in qty')
        return false
      }
    }

    try {
      const { data } = itemId ? await updateBundleItemMutation({
        variables: {
          input: dataInputs,
          product_parent_id: product_parent_id
        }
      }) : await createBundleItemMutation({
        variables: {
          input: dataInputs,
          product_parent_id: product_parent_id
        }
      });

      if ((data.createBundleItem && data.createBundleItem.error)) {
        setError(data.createBundleItem.error)
      }else if((data.updateBundleItem && data.updateBundleItem[0].error)){
        setError(data.updateBundleItem[0].error)
      } else {
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully added'
          }
        });
        setModalOpen(false);
      }
    } catch (err) {
      setError(err);
    }

  };

  const changeHandler = (event) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  }

  
  const changeSelectHandler = (option, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: option ? option.value : null
    });


  };

  const changeMultiSelectHandler = (options, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: options ? options.map(option => option.value) : []
    });

  };

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {`${itemId ? 'Edit' : 'Add'} bundled item`}
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <p>Updated information will be synchronized with ELMO and Plenty within the next hour.</p>
        <div className="form-group">
          <ModalError error={error} setError={setError} />
          <label htmlFor="name">
            PID <span style={{ color: 'red' }}>*</span>
          </label>

          {dataLoading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <>
              { itemId  ? (
                <Select
                  name="bundled_product_parent_id"
                  options={convertDataToOptions(pids)}
                  value={createDefaultOption(pids.find(x => x.id === formData.bundled_product_parent_id))}
                  onChange={changeSelectHandler}
                />
              ) : (
                <Select
                  name="bundled_product_parent_id"
                  options={convertDataToOptions(pids)}
                  defaultValue={formData.bundled_product_parent_id.map(i => createDefaultOption(pids.find(x => x.value === i)))}
                  onChange={changeMultiSelectHandler}
                  isMulti
                />
                )}
            </>
          )}

         </div>

          {!itemId && formData.bundled_product_parent_id && formData.bundled_product_parent_id.map((item, i) => (
            <div className="form-group" key={i}>
              <label htmlFor="name">
                Qty for "{ item }" <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                style={{width: '80px'}}
                type="number"
                id="product_qty"
                name="product_qty"
                className="form-control"
                required
                onChange={event => changeFieldHandler(event, i)}
                defaultValue={formData.product_qty??1}
              />
            </div>
          ))}

        { itemId && (
          <div className="form-group" key={itemId.id}>
            <label htmlFor="name">
              Qty for "{ formData.bundled_product_parent_id }" <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              style={{width: '80px'}}
              type="number"
              id="product_qty"
              name="product_qty"
              className="form-control"
              required
              onChange={changeHandler}
              defaultValue={formData.product_qty}
            />
          </div>
        ) }

      </div>
      <div className="modal-footer justify-content-start">
        <button type="submit" className="btn btn-primary">
            Save
        </button>

        {itemId && (
          <button onClick={() => deleteItem()} className="btn btn-danger">
            Delete
          </button>
        )}
      </div>
    </form>
  );
};

export default BundledItemsFormModal;
